import { hydrate } from "@/utils/form";

export default class PaymentToken {
    id = null;
    balance = null;
    name = null;
    description = null;
    expiresAt = null;
    createdAt = null;

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if(options.serialize) {
                this.serialize(object);
            }
            if(options.deserialize) {
                this.deserialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
    }
    deserialize (object) {
        object.balance = parseFloat(object.balance).toFixed(2);
        hydrate(this, object);
    }

}
