<template>
  <validation-observer
    ref="paymentTokenFormValidator"
    slim
  >
    <div class="mt-2">
      <b-row class="mt-2 mb-2">
        <b-col class="balance-title" cols="9" align="left">
          {{ $t('general.actions.update') }} « {{ paymentToken.name }} »
        </b-col>
        <b-col cols="3" align="right">
          <span @click="updatePaymentToken" class="d-bg-gray">
            <i class="pointer fa fa-check"></i>
          </span>
          <span
            @click="$emit('on:leave')"
            class="d-bg-gray">
            <i class="pointer fa fa-close"></i>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          {{ $t('components.form.client.details.body.right.balance.nb-credits') }}
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model="paymentToken.balance"
            class-name="background-light-blue-inputs"
            type="number"
            size="sm"
            :rules="validation.balance"
            error-text="validation.required"
          />
        </b-col>

        <b-col class="mt-2" cols="12">
          {{ $t('components.form.client.details.body.right.balance.note') }}
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model="labelOperation"
            :rules="validation.label"
            size="sm"
            place-holder="components.form.client.details.body.right.balance.note-placeholder"
            class-name="background-light-blue-inputs"
            error-text="validation.required"
          />
        </b-col>
        <b-col class="mt-2" cols="12">
          <div class="border-bottom-grey-dark">
          </div>
          {{ $t('components.form.client.details.body.right.balance.validity-date') }}
        </b-col>
        <b-col cols="12">
          <d-dropdown-form
            :hide="hide"
            :show="show"
            :right="true"
            identifier="date-dropdrown"
          >
            <template v-slot:dropdownbutton>
              <div class="has-calendar">
                <span
                  class="fa fa-calendar form-control-feedback">
                </span>
                <d-text-field
                  :value="datePickerValue"
                  class-name="background-light-blue-inputs"
                  readonly="true"
                  size="sm"
                  @on:text-field:click="show=!show"
                />
              </div>
            </template>
            <template v-slot:dropdownbody>
              <d-calendar
                v-model="paymentToken.expiresAt"
                @on:calendar-change="updateTextFieldValue"
              />
            </template>
          </d-dropdown-form>
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>
<script>
import PaymentToken from "@/classes/doinsport/PaymentToken";
import {postOperation} from "@api/doinsport/services/client/payment-token/operation/operation.api";
import {fromIdToIriReference} from "@/utils/form";
import {getPaymentTokenModel, putPaymentToken} from "@api/doinsport/services/client/payment-token/payment-token.api";

export default {
  props: {
    paymentToken: {
      type: Object,
      default: () => {
      },
    }
  },
  data: () => ({
    model: null,
    labelOperation: null,
    datePickerTextFormat: null,
    validation: require('@validation/entities/doinsport/Operation.json')
  }),
  computed: {
    datePickerValue() {
      return this.datePickerTextFormat ? this.datePickerTextFormat : this.$t('general.actions.undefined');
    }
  },
  methods: {
    notADate(paymentToken) {
      return false;
    },
    updatePaymentToken() {
      try {
        this.$refs.paymentTokenFormValidator.validate().then((valid) => {
          if (valid) {
            const deserializedPaymentToken = new PaymentToken(this.paymentToken, {deserialize: true});
            const toPost = {
                label: this.labelOperation,
                paymentToken: fromIdToIriReference('/clubs/clients/payment-tokens', this.paymentToken.id)
              }
            ;
            const balance = deserializedPaymentToken.balance - parseFloat(this.model.balance);

            if (balance > 0) {
              Object.assign(toPost, {credit: balance});
            } else {
              Object.assign(toPost, {debit: Math.abs(balance)});
            }

            if (balance !== 0) {
              postOperation(toPost).finally(() => {
                putPaymentToken({
                  id: deserializedPaymentToken.id,
                  expiresAt: this.paymentToken.expiresAt
                }).finally(() => {
                  this.$emit('on:leave');
                });
              })
              ;
            } else {
              if (this.paymentToken.expiresAt === null) {
                this.$emit('on:leave');
              } else {
                putPaymentToken({
                  id: deserializedPaymentToken.id,
                  expiresAt: this.paymentToken.expiresAt
                }).finally(() => {
                  this.$emit('on:leave');
                });
              }
            }
          }
        });
      } catch (e) {
      }
    },
    formatDate(datePickerVal) {
      if (datePickerVal !== null) {
        this.paymentToken.expiresAt = this.$moment(datePickerVal);
        return this.$moment(datePickerVal).format('DD/MM/YYYY')
      }
    },
    updateTextFieldValue(value) {
      this.datePickerTextFormat = this.formatDate(value);
    }
  },
  created() {
    this.datePickerTextFormat = this.formatDate(this.paymentToken.expiresAt);
    getPaymentTokenModel(this.paymentToken.id).then((response) => {
      this.model = response.data;
    });
  },
}
</script>
<style scoped>
.balance-title {
  font: normal normal 900 14px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.d-bg-gray:hover {
  color: #707070;
}

/deep/ .has-calendar .form-control-feedback {
  line-height: 2.675rem;
}

</style>
